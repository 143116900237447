.requiredLabel::after{
    color: #ff4d4f;
    content: '*';
    margin-left: 4px;
}

.ant-col-7 {
    max-width: 22.166667% !important;
}

.ant-col-voucher {
    flex-direction: column !important;
    margin-bottom: 10px !important;
    .ant-col-7 {
        max-width: 100% !important;
    }
    .ant-form-item-label{
        text-align: left !important;
    }
    .ant-input{
        width: 100% !important;
    }
}

.ant-col-field {
    display: flex;
    flex-direction: column !important;
    margin-bottom: 10px !important;
    .ant-col-7 {
        max-width: 100% !important;
        text-align: left !important;
    }
}

.capitalize {
    text-transform: capitalize;
}

.required::before{
    color: #ff4d4f;
    content: '* ';
    margin-left: 4px;
    font-size: 14px;
}

.location_whole{
    width: calc(100% - 48px) !important
}

.location_half{
    width: calc(100% - 394px) !important
}