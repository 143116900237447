// BG COLORS
$bg-yellow: #FDB415 !default;

// TEXT COLOR 
$white: #ffffff;
$blue: #0B1339;
$prime: #FDB415;

// FLEX 
.flex-col {
    display: flex !important;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.wrapper {
    display: flex;
    flex-wrap: wrap;
}

.grow-1 {
    flex-grow: 1;
}

// ALIGN 
.f-align-start {
    align-items: flex-start;
}

.f-align-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.f-align-end {
    align-items: flex-end !important;
}

// JUSTIFY 
.justify-start {
    justify-content: flex-start
}

.justify-center{
    justify-content: center;
}

.justify-space-between{
    justify-content: space-between;
}

.justify-end {
    justify-content: flex-end;
}

.align-center {
    align-items: center;
}

.date_range{
    width: 100%;
    align-items: center;
    justify-content: flex-end !important;
    display: flex;
    flex-direction: row;
}

.btn {
    background: $bg-yellow;
    color: $white;
    outline: none;
    border: 0;
    // padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    // text-transform: uppercase;
    // font-weight: bold;
    
    &:not(:disabled) {
        cursor: pointer;

        &:hover {
            background: darken($bg-yellow, 8%);
            color: $blue;
        }
    }
}

//btn
.ant-btn{
    font-weight: 600 !important;
}


// COLOR 
.text-prime {
    color: $prime;
}

.color-link {
    color: $prime;
    outline: none;
    cursor: pointer;

    &:hover {
        color: darken($prime, 8%);
    }
}

// TEXT ALIGNMENT
.text-center {
    text-align: center;
}

// PADDING 
.padding-10-0 {
    padding: 10px 0;
}

.padding-20-0 {
    padding: 20px 0;
}

// MARGIN 
.margin-t-20 {
    margin-top: 20px;
}

.margin-l-7{
    margin-left: 7px;
}

.margin-l-8{
    margin-left: 8px;
}
// FONT SIZE 
.font-10{
    font-size: 10px;
}

.font-14 {
    font-size: 14px;
}

.font-20{
    font-size: 20px;
}

.font-16 {
    font-size: 16px;
}

//Margins
.margin-top-12{
    margin-top: 12px;
}

.margin-top-10{
    margin-top: 10px;
}

.margin-bottom-20{
    margin-bottom: 20px;
}
.margin-bottom-15{
    margin-bottom: 15px;
}

.padding-right-10{
    padding-right: 10px;
}

.padding-right-5{
    padding-right: 5px;
}

.padding-left-5{
    padding-left: 5px;
}


.ant-btn {
    font-size: 16px !important;
    min-height: 6vh;
}

.ant-btn-primary{
    background: #FDB415 !important;
    border-color: #FDB415 !important;
}

.modal_add_btn{
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
}

.require {
    color: #ff4d4f;
}

.category_form .ant-form-item-label{
    // max-width: unset !important
    max-width: 120px !important;

}


//antd
.ant-page-header-heading-title{
    font-weight: 700 !important;
}

.ant-modal-title{
    font-weight: 700 !important;
}