$white: #ffffff !default;
$gray: #ccc !default;
$prime: #1890ff !default;
$blue: #0B1339;

.header{
    display: flex;
    background: #0B1339;
    color: #ffffff;
    position: relative;
    justify-content: space-between;
    height: 75px;

    @at-root #{&}__menu{
        ul{
            border-bottom: 1px solid #ccc;
            li{
                border-right: 1px solid rgba($gray, 0.3);
                font-weight: 500;
            }
        }
    }
    
    @at-root #{&}__left{
        display: flex;
        width: 40%;
        .header_tab{
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding: 0 18px;
            font-size: 14px;
            
            img{
                width: 55px;
            }
            a{
                color: white;
            }
           
        }

        .header__logo{
            .logo_img{
                width: 15vh;
                img{
                    width: 100%;
                }
            }
        }
    }

    @at-root #{&}__logo{
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid rgba($gray, 0.3);
    }

    @at-root #{&}__right{
        display: flex;

        .action-icon{
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding: 0 18px;
            font-size: 18px;
            border-left: 1px solid rgba($gray, 0.3);
        }

        .profile-container{
            display: flex;
            justify-content: center;
            align-items: center;

            padding: 0 18px;
            border-left: 1px solid rgba($gray, 0.3);
            .profile-details{
                display: flex;
                flex-direction: column;
                padding-left: 10px;
                line-height: 1.3;
                label{
                    font-weight: bold;
                }

                span{
                    font-style: italic;
                }
            }
    
            .profile-icon{
                width: 40px;
                height: 40px;
                background: $prime;
            }
        }

        .primary{
            color: #FDB415;
        }
    }
}