
.order{
    .order_icon{
        color: #FDB415;
    }
    .order_info{
        font-size: 14px;
        padding: 5px 0;
    }
    .info_label{
        font-weight: bold;
    }
    .order_item{
        .order_data{
            display: flex;
            justify-content: space-between;
            &.nested-option {
                padding: 0 0 0 48px;
            }
            span{
                padding: 2px 0;

                &.text-bold {
                    font-weight: bold;
                }
            }
            .total{
                font-weight: bold;
                font-size: 16px;
            }
        }
    }
    .order_divider{
        margin: 10px 0;
    }

    .custom_divider{
        margin: 6px 0;
    }

    .ant-tabs-tab {
        margin: 0 100px 0 0 !important;
        font-size: 16px;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        font-weight: 500 !important;
    }
    .ant-tabs-tab-btn {
        font-weight: 500 !important;
    }
}
.order_status{
    display: flex;
    div{
        font-weight: bold;
        flex-direction: row;
    }
}
.status_label{
    font-weight: bold;
    display: flex !important;
    justify-content: flex-end !important;
    padding: 5px;
}

.flex-end{
    display: flex;
    justify-content: flex-end;
}

.padding_right{
    padding-right: 17px;
}

.total_amount{
    padding-right: 16px;
}
.ant-input-affix-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -ms-inline-flexbox;
    display: inline-flex;
    .ant-input-affix-wrapper > input.ant-input {
        padding: 0;
        border: none;
        outline: none;
    }
    .ant-input {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-variant: tabular-nums;
        list-style: none;
        -webkit-font-feature-settings: 'tnum', "tnum";
        font-feature-settings: 'tnum', "tnum";
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 0;
        padding: 0;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        line-height: 1.5715;
        background-color: #fff;
        background-image: none;
        border: 0px solid #d9d9d9;
        border-radius: 2px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }
}

.order_amount{
    text-align: right;
    padding-right: 8vh !important;
}


