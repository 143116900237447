.button-text{
    color: #1890ff;
    cursor: pointer;
    &:active{
        color: #096dd9;
    }

    &:hover{
        color: #40a9ff;
    }
}

.button-text-danger{
    color: red;
    cursor: pointer;
    &:active{
        color: red;
    }

    &:hover{
        color: red;
    }
}

.hideFooter .ant-modal-footer{
    display: none;
}

.btn-text-primary {
    color: #337ab7;
    cursor: pointer;
}