$prime: #1890ff !default;
.login-form{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .login-form-forgot{
        color: #FDB415;
    }

    form {
        text-align: center;
        padding: 24px 30px;
        padding-bottom: 0;

        box-shadow: 1px 1px 13px 1px #e7e7e7;
        border-radius: 5px;

        p{
            max-width: 330px;
        }

        .logo-container{
            width: 120px;
            height: 120px;
            border-radius: 50%;
            margin: 0 auto;
            background: #FDB415;
        }

        .ant-btn + .ant-btn{
            margin-left: 8px;
        }
    }
}