// BG COLORS
$bg-yellow: #FDB415 !default;

// TEXT COLOR 
$white: #ffffff;
$blue: #0B1339;
$prime: #FDB415;

.promo{
    .ant-picker {
        width: 100%;
    }
    .half_input {
        width: 60% !important;
    }

    .red{
        background-color: red;
    }
    .align_center{
        display: flex;
        justify-content: center;
    }

    .ant-table-small .ant-table-thead > tr > th{
        padding-right: 14px !important;
    }
    
    .flex_end{
        display: flex;
        justify-content: flex-end;
    }

    .flex_center{
        display: flex;
        justify-content: center;
    }

    .margin_bottom{
        margin-bottom: 14px !important;
    }

    .align_right{
        display: flex;
        justify-content: right;
    }

    .promo_code_btn{
        display: flex;

        .upload_btn{
            margin-left: 10px;
            color: #ffffff;
            background: #40a9ff;
            height: 42px;
        }

        .upload_btn:hover{
            background: #40a9ff;
            color: #0B1339;
        }
    }
    
}

.promo-code-list {
    list-style: none;
    margin: 0;
    padding: 0;

    margin-top: 20px;
    width: 100%;

    li {
        padding: 10px;
        font-weight: 600;
        display: flex;
        justify-content: space-between;

        &:nth-child(odd) {
            background-color: #f7f7f7;
        }
    }
}

.promo_btn {
    background: $bg-yellow;
    margin-left: 5px;
    color: $white;
    outline: none;
    border: 0;
    padding: 5px 10px;
    text-align: center;
    // font-weight: bold;
    
    &:not(:disabled) {
        cursor: pointer;

        &:hover {
            background: darken($bg-yellow, 8%);
            color: $blue;
        }
    }
}