.payables {
    .ant-table-small .ant-table-thead > tr > th {
        width: 200px;
    }

    .payable_lable {
        font-weight: bold;
    }
    .col-flex-end {
        display: flex;
        justify-content: flex-end;
    }
    .payable_row {
        padding-bottom: 10px;
    }

    .card_info {
        display: flex;
        justify-content: space-between;
        .form-item-center {
            padding: 0 10px;
        }
    }
    .ant-form-item-label{
        text-align: left !important;
    }

    .row_background_color {
        background-color: #989898;
        color: #ffffff;
    }

    .total_backgroun_color {
        background-color: #fafafa;
        font-size: 16px !important;
        font-weight: 600 !important;
        margin-top: 20px;
    }
    .row_background_color:hover {
        color: #000000d9;
    }

    .ant-from-item-btn {
        justify-content: flex-end;
        display: flex;
    }
    .submit_btn {
        width: 80px;
        height: 40px;
        font-size: 16px;
    }
    .align_right {
        text-align: right;
    }
    .align_center {
        display: table-cell;
        text-align: center;
    }
    .history_no_wrap_start {
        white-space: nowrap;
        width: 10% !important;
        padding-left: 20px !important;
    }

    .history_no_wrap {
        white-space: nowrap;
        text-align: end;
        width: 10% !important;
        padding-left: 20px !important;
    }

    .amount_right {
        text-align: right;
        padding-right: 20vh !important;
        white-space: nowrap;
    }

    .no_wrap {
        white-space: nowrap;
    }

    .total_font_weight {
        font-weight: 600 !important;
    }

    .total_font_size {
        font-size: 16px !important;
    }

    .bold_text {
        font-weight: bold;
    }

    .ant-tabs-tab {
        margin: 0 100px 0 0 !important;
        font-size: 16px;
    }

    .ant-tabs-tab-btn {
        font-weight: 500 !important;
    }
}

.amount_right {
    text-align: right;
    padding-right: 20vh !important;
    white-space: nowrap;
}

.total_amount_right {
    text-align: end !important;
    padding-right: 5vh !important;
}

.preparation_date {
    white-space: nowrap;
}

.breakdown {
    margin-top: 10px;
    display: flex;
    justify-content: left;

    .breakdown_divider {
        margin: 10px 0;
    }

    .breakdown_title {
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        margin: 20px 0;
    }
    .breakdown_delivery_details {
        width: 27%;

        .sub-breakdown {
            width: 270px;
            padding-right: 5px;
        }
    }
    .breakdown_pickup_details {
        width: 39%;
        .sub-breakdown {
            width: 342px;
            padding-right: 5px;
        }
    }
    .breakdown_data {
        display: flex;
        justify-content: space-between;
        .info_label {
            font-weight: 600;
            font-size: 14px;
        }
        .total_label {
            font-weight: 600;
            font-size: 16px;
        }
    }
}
